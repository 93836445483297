<template>
  <div>
    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />
      <main id="content" class="main-content" role="main">
        <div class="container-fluid pl-4 pr-0 main-content__has-sidebar">
          <div class="content-left">
            <div
              class="page-header d-flex justify-content-between align-items-center mb-4 pt-4"
            >
              <h1 class="color-sec">Home</h1>

              <ul class="list-inline" v-if="criarPova">
                <li class="list-inline-item">
                  <router-link to="/criar-prova" class="btn btn-classic">
                    Criar Prova
                  </router-link>
                </li>
              </ul>
            </div>

            <div class="page-content">
              <div v-if="this.isStatusSet('Disponibilizado').length > 0">
                <list-provas-em-breve
                  :provas="this.isStatusSet('Disponibilizado')"
                />
              </div>

              <list-provas-all
                :provas="this.isStatusSet('Aprovado')"
                title="Disponibilizar"
                path="Aprovado"
                status="EmRascunho"
                button-more="Listar"
                v-if="this.isStatusSet('Aprovado').length > 0"
              />
              <list-provas-all
                :provas="this.isStatusSet('Concluido')"
                title="Corrigir"
                path="Concluido"
                button-more="Ver provas para correção"
                v-if="this.isStatusSet('Concluido').length > 0"
              />
            </div>
          </div>

          <div
            class="content-right min-vh-100"
            style="background-color: #EBEBEB"
          >
            <div class="page-aside w-100 pt-4 pr-4 has-scroll">
              <div class="widget mb-4">
                <vc-date-picker v-model="date" :color="corEmpresa" :value="null" />
              </div>
              <h2 class="color-sec fs-20 mb-3">Próximas Provas</h2>

              <proximas-provas
                v-for="prova in proxProvas"
                :key="prova.id"
                :prova="prova"
              />

              <div class="widget text-right">
                <a href="#/agenda" class="link font-bold"
                  >+ Ver agenda completa</a
                >
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <p style="color: black; font-size: 12px; text-align: center">
      Versão 1.4.3
    </p>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import ListProvasEmBreve from "@/components/provas/list/ListProvasEmBreve";
import ListProvasAll from "@/components/provas/list/ListProvasAll";
import ProximasProvas from "@/components/provas/ProximasProvas";
import VCalendar from "v-calendar";
import { constantes } from "@/constants";

Vue.use(VCalendar, {
  componentPrefix: "vc",
});

export default {
  nome: "Dashboard",
  components: {
    MainTopBar,
    MainLeftSideBar,
    ListProvasEmBreve,
    ListProvasAll,
    ProximasProvas,
  },
  data() {
    return {
      showLeftMenu: true,
      date: new Date(),
      provas: [],
      proxProvas: [],
      criarPova: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    corEmpresa: {
      get () {
        if(constantes.EMPRESA === 'veloz'){
          return 'blue';
        }else if(constantes.EMPRESA === 'uniavan'){
          return 'green';
        }else{
          return '';
        }
      }
    }
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();
  },
  methods: {
    loadData() {
      let roles = localStorage.getItem(constantes.STORAGE_UNIAVAN_USER_ROLES);
      let arrayRoles = roles.split(",");
      let endpoint = "";
      console.log(arrayRoles);
      if (arrayRoles.includes("ROLE_PROFESSOR")) {
        this.criarPova = true;
        endpoint = "provas-criadas";
      }
      let filtro = "";
      if (
        this.$route.params.filter != "" &&
        this.$route.params.filter != undefined
      ) {
        filtro = "/" + this.$route.params.filter;
      }
      this.$api
        .get(endpoint + filtro)
        .then((response) => {
          if (arrayRoles.includes("ROLE_PROFESSOR")) {
            this.$api
              .get("users/info")
              .then((user) => {
                
                response.data.forEach((res) => {
                  if (res.criadaPorEmail == user.data.user.email) {
                    this.provas.push(res);
                  }
                });
                console.log(this.provas);
              })
              .catch((error) => {
                console.log(error);
                console.log(error.response);
              });
          } else {
            console.log(response);
            response.data.forEach((res) => {
              if (res.statusCodigo != "Rascunho") {
                if(res.turmaInstituicaoId == constantes.STORAGE_UNIAVAN_ID_INSTITUICAO){
                  this.provas.push(res);
                }
              }
            });
            // this.provas = response.data;
          }

          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    isStatusSet(status) {
      return this.provas.filter((prova) => {
        console.log(prova);
        return prova.statusCodigo == status;
      });
    },
  },
};
</script>
